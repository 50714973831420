import React, { useEffect, useState } from "react"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { FREE, ON_DEMAND, SUBSCRIPTION } from "../enums/PricePolicies"
import useCommonQueries from "../hooks/useCommonQueries"
import { navigate } from "gatsby"
import {
  isSubscriptionValid,
  isUndefined,
  mapItemTypeFromTypename,
  translatePath,
  uiHandleError,
  uiHandleWarning,
} from "../utils"
import { useAuth0 } from "@auth0/auth0-react"
import useFavorite from "../hooks/useFavorite"
import { LAWFUL_SUBSCRIPTION_ROLES } from "../enums/UserRoles"
import { COMING_SOON } from "../enums/RecordStates"
import { useTranslation } from "react-i18next"

const PURCHASABLE_ITEMS = [
  "eventure_certifications",
  "eventure_bundles",
  "bundles",
  "certifications",
]

const withOwnershipCheck = Component => {
  return props => {
    const { t } = useTranslation()
    const { data, loading: parentLoading } = props

    const itemId = data.id
    const itemType = mapItemTypeFromTypename(data.__typename)
    const isVideo = data.__typename === "eventure_videos"

    const [itemToCheck, setItemToCheck] = useState(null)
    const [loading, setLoading] = useState(true)
    const [canPurchase, setCanPurchase] = useState(false)
    const [canPlay, setCanPlay] = useState(false)
    const [progress, setProgress] = useState(0)
    const [hasActiveSubscription, setHasActiveSubscription] = useState(false)
    const [isFavorite, setIsFavorite] = useFavorite(itemId, itemType)

    const { isAuthenticated } = useAuth0()
    const [{ userInventory, userSubscription, user, isLoadingUser }] =
      useGlobalValue()
    const {
      createPurchaseIntent,
      getVideoHistory,
      getLastWatchVideoIdInCourse,
      getLastWatchVideoSlugInCourse,
      getCourseProgress,
    } = useCommonQueries()

    const calcVideoProgress = async () => {
      try {
        const time = await getVideoHistory({ videoId: data.id })
        const progress = (time / data.duration) * 100
        setProgress(progress)
      } catch (error) {
        console.error(error)
        setProgress(0)
      }
    }

    const calcCourseProgress = async () => {
      try {
        const _progress = await getCourseProgress({ courseId: data.id })
        setProgress(_progress)
      } catch (error) {
        setProgress(0)
      }
    }

    useEffect(() => {
      if (PURCHASABLE_ITEMS.includes(itemToCheck?.__typename)) {
        if (!isUndefined(userInventory)) {
          if (
            userInventory.filter(({ item_id }) => itemToCheck.id === item_id)
              .length
          ) {
            setCanPlay(true)
            setCanPurchase(false)
          } else {
            setCanPlay(false)
            setCanPurchase(true)
          }
          setLoading(false)
        }
      }
    }, [itemToCheck, userInventory, user])

    useEffect(() => {
      if (itemToCheck?.price_policy === FREE) {
        setLoading(false)
        setCanPlay(true)
        setCanPurchase(false)
      }
    }, [itemToCheck])

    useEffect(() => {
      if (itemToCheck?.price_policy === ON_DEMAND) {
        if (!isUndefined(userInventory)) {
          if (
            userInventory.filter(({ item_id }) => itemToCheck.id === item_id)
              .length
          ) {
            setCanPlay(true)
            setCanPurchase(false)
          } else {
            setCanPlay(false)
            setCanPurchase(true)
          }
          setLoading(false)
        }
      }
    }, [userInventory, itemToCheck, user])

    useEffect(() => {
      if (itemToCheck?.price_policy === SUBSCRIPTION) {
        if (LAWFUL_SUBSCRIPTION_ROLES.includes(user?.role)) {
          setCanPlay(true)
          setCanPurchase(false)
          setLoading(false)
          return
        }

        if (isSubscriptionValid(userSubscription)) {
          setCanPlay(true)
          setCanPurchase(false)
          setHasActiveSubscription(true)
        } else {
          setCanPlay(false)
          setCanPurchase(true)
          setHasActiveSubscription(false)
        }
        setLoading(false)
      }
    }, [userSubscription, itemToCheck])

    useEffect(() => {
      if (!itemToCheck) {
        return
      }

      if (
        ["eventure_certifications", "certifications"].includes(
          itemToCheck.__typename
        )
      ) {
        return
      }

      if (isVideo) {
        calcVideoProgress()
      } else {
        calcCourseProgress()
      }
    }, [canPlay, itemToCheck])

    useEffect(() => {
      let temp
      if (data.__typename === "eventure_courses") {
        temp = data
      } else {
        temp = data?.course_rel?.course ?? data
      }
      setItemToCheck(temp)
    }, [data])

    const handleFavoriteChange = async () => {
      setIsFavorite(!isFavorite)
    }

    const onPurchase = async () => {
      if (!isAuthenticated) {
        return navigate(
          `${translatePath("/auth/sign-in")}?redirect=${window.location.href}`,
          {
            replace: true,
          }
        )
      }

      setLoading(true)
      try {
        switch (itemToCheck.price_policy) {
          case SUBSCRIPTION:
            navigate(translatePath(`/subscribe`))
            break
          default:
            const purchaseId = await createPurchaseIntent(
              itemType,
              itemToCheck.id
            )
            navigate(`${translatePath("/checkout")}?id=${purchaseId}`)
            break
        }
      } catch (error) {
        uiHandleError({ error })
      }
      setLoading(false)
    }

    const onPlay = async () => {
      if (!isAuthenticated) {
        navigate(
          `${translatePath("/auth/sign-in")}?redirect=${window.location.href}`
        )
      }

      if (itemToCheck.state === COMING_SOON) {
        return uiHandleWarning({ message: t("") })
      }

      if (isVideo) {
        navigate(`${translatePath("/watch")}/${data.seo.slug}`)
      } else {
        // last video
        const lastVideoSlug = await getLastWatchVideoSlugInCourse({
          courseId: data.id,
        })
        navigate(`${translatePath("/watch")}/${lastVideoSlug}`)
      }
    }

    const onShowMoreInfo = async () => {
      if (isVideo) {
        navigate(`${translatePath("/video")}/${data.seo.slug}`)
      } else {
        navigate(`${translatePath("/course")}/${data.seo.slug}`)
      }
    }

    return (
      <Component
        {...props}
        loading={loading || parentLoading || isLoadingUser}
        canPurchase={canPurchase}
        canPlay={canPlay}
        isFavorite={isFavorite}
        progress={progress}
        onToggleFavorite={handleFavoriteChange}
        hasActiveSubscription={hasActiveSubscription}
        onPurchase={onPurchase}
        onPlay={onPlay}
        showFavoriteButton={isAuthenticated}
        onShowMoreInfo={onShowMoreInfo}
      />
    )
  }
}

export default withOwnershipCheck
