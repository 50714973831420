import React, { useMemo } from "react"
import PageRoot from "../components/PageRoot"
import { graphql, Link, navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { CERTIFICATION, DIPLOMA, MASTER } from "../enums/CertificationTypes"
import EventureGrid from "../components/EventureGrid"
import ContainerWithPadding from "../components/ContainerWithPadding"
import CertificationTile from "../components/CertificationTile"
import withOwnershipCheck from "../components/withOwnershipCheck"
import withCertificationState from "../components/withCertificationState"
import { translatePath } from "../utils"
import withTileBadgeCheck from "../components/withTileBadgeCheck"
import { CertificationBadge } from "../components/Labels"
import { Button, Col, Row } from "antd"
import wallpaper from "../../static/assets/images/hexagon-bg.jpg"
import GenericHeader from "../components/GenericHeader"

const AllCertificationPage = ({ data }) => {
  const { t } = useTranslation()

  const headerData = {
    title: t("title:heroCertificationTitle1"),
    subtitle: t("title:heroCertificationSubtitle"),
    description: t("title:heroCertificationDescription"),
    wallpaper: "/assets/images/wall-cert-3d.png",
    actions: [],
  }

  const renderCertificationTile = (item, index) => {
    const TileComponent = withCertificationState(
      withTileBadgeCheck(withOwnershipCheck(CertificationTile))
    )

    return (
      <TileComponent
        key={index}
        data={item}
        onClick={() => {
          navigate(`${translatePath("/online-tests")}/${item.seo.slug}`)
        }}
        extra={[
          <CertificationBadge badge={item.badge} title={item.badge_title} />,
        ]}
      />
    )
  }

  return (
    <PageRoot title={t("label:myCertifications")}>
      <GenericHeader
        data={{
          title: headerData.title,
          subtitle: headerData.subtitle,
          wallpaper: wallpaper,
        }}
      />

      <ContainerWithPadding size="large">
        <EventureGrid
          title={headerData.description}
          dataSource={data.eventure.certifications}
          renderItem={renderCertificationTile}
        />
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query allCertifications {
    eventure {
      certifications(where: { state: { _eq: PUBLISHED } }) {
        ...PreviewCardCertification
      }
    }
  }
`

export default AllCertificationPage
